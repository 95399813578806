import { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { getPrefillId, submitVote, VOTE_OPTIONS } from "../utils";

function VoteInner() {
  const [id, setId] = useState(getPrefillId());
  const [value0, setValue0] = useState(null);
  const [value1, setValue1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handlerSubmit = async(e) => {
    e.preventDefault();
    if (!id.length) {
      alert('Bạn chưa nhập mã số cá nhân');
      return;
    }
    if (!value0 || !value1) {
      alert('Bạn chưa chọn người bạn muốn bình chọn');
      return;
    }
    setLoading(true);
    const {error} = await submitVote(id, value0, value1);
    if (error) {
      if (error.match(/code does not exist/))
        alert('Mã số cá nhân không hợp lệ');
      else if (error.match(/modify/))
        alert('Bạn đã gửi bình chọn rồi, bạn không thể sửa lại bình chọn nữa. Chú ý rằng, mỗi người có một mã QR riêng.');
      else if (error.match(/closed/))
        alert('Cổng bình chọn đã đóng');
      else
        alert('ERROR: ' + error);
    } else {
      setDone(true);
    }
    setLoading(false);
  };

  return <Card>
    <Card.Body>
      <Card.Title>
        <b>Bình chọn phần thi tài năng</b>
      </Card.Title>
      {!done && <Card.Text>
        <br/>
        <Form onSubmit={handlerSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Mã số cá nhân</Form.Label>
            <Form.Control type="text" placeholder="XXX-XX" value={id} onChange={(e) => setId(e.target.value)} style={{textTransform: 'uppercase'}} disabled={loading} />
            <Form.Text className="text-muted">
              Mã số cá nhân được ghi trên ticket đính kèm khi checkin
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Với King, bạn bình chọn cho...</Form.Label>

            {VOTE_OPTIONS.filter(({type}) => type === 'king').map(({value: v, label}) =>
              <Card className={`vote-option ${v === value0 ? 'selected' : ''}`} onClick={() => !loading && setValue0(v)} key={v}>
                <Card.Body>
                  <Card.Text>{label}</Card.Text>
                </Card.Body>
              </Card>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Với Queen, bạn bình chọn cho...</Form.Label>

            {VOTE_OPTIONS.filter(({type}) => type === 'queen').map(({value: v, label}) =>
              <Card className={`vote-option ${v === value1 ? 'selected' : ''}`} onClick={() => !loading && setValue1(v)} key={v}>
                <Card.Body>
                  <Card.Text>{label}</Card.Text>
                </Card.Body>
              </Card>
            )}
          </Form.Group>

          <br/>

          <Form.Group className="mb-3">
            <Form.Text className="text-muted">
              Chú ý: bạn sẽ không thể sửa lại bình chọn một khi đã gửi
            </Form.Text>
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Xin chờ...' : 'Gửi'}
          </Button>
        </Form>
      </Card.Text>}

      {done && <Card.Text>
        <br/>
        Lựa chọn của bạn đã được lưu lại. Xin cảm ơn bạn!
        <br/><br/>
      </Card.Text>}
    </Card.Body>
  </Card>;
}

function Vote() {
  return <>
    <Container>
      <Row>
        <Col></Col>
        <Col md={6} lg={6}>
          <br/><br/>
          <VoteInner />
        </Col>
        <Col></Col>
      </Row>
    </Container>
  </>;
}

export default Vote;