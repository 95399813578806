import axios from "axios";

export const VOTE_OPTIONS = [
  { value: 'a', type: 'king', label: 'SBD 03 - Trần Đức Duy Bảo 10 Hoá 1' },
  { value: 'b', type: 'king', label: 'SBD 04 - Lê Minh Khôi 10 Nga' },
  { value: 'c', type: 'queen', label: 'SBD 01 - Nguyễn Vân Anh 10 Nga' },
  { value: 'd', type: 'queen', label: 'SBD 02 - Phạm An Như 10 Nga' },
];

export const getVoteResults = async () => {
  const {data} = await axios.post(
    'https://stuffs.ngxson.com/prom22/submit.php',
    'action=getall', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  return data;
};

export const submitVote = async (id, value0, value1) => {
  try {
    const cleanId = id.replace(/[^a-zA-Z0-9]+/g, '').toUpperCase();
    const {data} = await axios.post(
      'https://stuffs.ngxson.com/prom22/submit.php',
      `action=save&id=${
        encodeURIComponent(cleanId)
      }&value0=${
        encodeURIComponent(value0)
      }&value1=${
        encodeURIComponent(value1)
      }`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
    return data;
  } catch (e) {
    return {error: 'Lỗi kết nối mạng, xin vui lòng thử lại.'};
  }
};

export const doAction = async (action, password) => {
  try {
    const {data} = await axios.post(
      'https://stuffs.ngxson.com/prom22/submit.php',
      `action=${encodeURIComponent(action)}&password=${encodeURIComponent(password)}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
    return data;
  } catch (e) {
    return {error: 'Lỗi kết nối mạng, xin vui lòng thử lại.'};
  }
};

export const getPrefillId = () => {
  const matched = window.location.pathname.match(/\/([a-zA-Z0-9]{3})([a-zA-Z0-9]{2})/);
  if (!matched) return '';
  return `${matched[1]}-${matched[2]}`;
};