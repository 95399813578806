import { useState, useEffect } from "react";
import { VOTE_OPTIONS, getVoteResults } from "../utils";

const useVoteResult = () => {
  const [people, setPeople] = useState([...VOTE_OPTIONS]);
  const [closed, setClosed] = useState(null);

  useEffect(() => {
    const update = async () => {
      if (window.isVoteClosed) return;
      try {
        const {recents, votes, closed} = await getVoteResults();
        setPeople(people => people.map(p => {
          const {count} = votes.find(v => v.value === p.value) || {};
          return {
            ...p,
            count: count || 0,
            recents: recents
              .filter(v => v.value === p.value)
              .map(({id}) => id.replace(/\+.*/, '')),
          }
        }));
        setClosed(closed);
        if (closed) window.isVoteClosed = true;
      } catch (e) {
        // ignored
      }
    };
    const intervalId = setInterval(update, 5000);
    update();
    return () => clearInterval(intervalId);
  }, []);

  return {people, closed};
};

export default useVoteResult;