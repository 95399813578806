import { Navbar, Container, Nav } from "react-bootstrap";

function Navigation() {
  return (<>
    <Navbar variant="dark" bg="primary" expand="lg">
      <Container>
        <Navbar.Brand href="">Sevellynna Prom 2024</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="">Vote</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>);
}

export default Navigation;