import './bootstrap.css';
import Navigation from './components/Navigation';
import Vote from './components/Vote';
import { useState } from 'react';
import Presentation from './components/Presentation';

function App() {
  const [isPresentation] = useState(window.location.pathname.match(/presentation/));

  return <>
    <Navigation />
    {isPresentation && <Presentation />}
    {!isPresentation && <Vote />}
    <br/>
    <br/>
    <div style={{opacity: '0.7', fontSize: '80%', display: 'flex', justifyContent: 'center'}}>
      Made by&nbsp;
      <a href="https://poet.cat" rel="noreferrer" target="_blank" style={{color: 'white'}}>The Poet Cat</a>
      &nbsp;&nbsp; <img src="https://cdn-gcs.ngxson.com/nui-art/2022/01/icon.o8ojfz-150x150.png" style={{height: '20px'}} alt="The Poet Cat" />
    </div>
    <br/>
    <br/>
  </>;
}

export default App;
