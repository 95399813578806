import { useState } from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import useVoteResult from "../hooks/use-vote-result";
import { doAction } from "../utils";

function VoterChips({id}) {
  return <div class="voter">{id.substr(0, 3)}-{id.substr(3, 2)}</div>;
}

function ResultDisplay({ people, type, closed }) {
  const arr = people
    .filter(p => p.type === type.toLowerCase())
    .sort((a, b) => (b.count || 0) - (a.count || 0));

  return <Card>
    <Card.Body>
      <Card.Title>
        <b>&nbsp;{type}</b>
      </Card.Title>
      <Card.Text>
        <br/>
        {arr.map(({label, count, recents}, i) => <Card key={label} className="vote-result">
          <Card.Body>
            <Card.Text>
            ({count === undefined ? '...' : count} lượt)<br/>
              <b className="label">
                {closed && count > 0 && i === 0 && '👑  '} {label}
              </b>
              {!closed && <>
                <br/>
                <small style={{color: '#ccc'}}>Bình chọn mới nhất: </small>
                {recents && recents.map(id => <VoterChips key={id} id={id} />)}
                <small style={{color: '#ccc'}}>...</small>
              </>}
            </Card.Text>
          </Card.Body>
        </Card>)}
      </Card.Text>
    </Card.Body>
  </Card>;
}

function ControlArea({ closed }) {
  const [password, setPassword] = useState('');
  const [action, setAction] = useState(null);

  const submit = async (e) => {
    e.preventDefault();
    const {error} = await doAction(action, password);
    if (error) alert(error);
    else window.location.reload();
    //setAction(null);
  };

  const askPassword = (action) => {
    setAction(action);
    setPassword('');
  };

  return <Card>
    {!action && <Card.Body>
      Điều khiển:&nbsp;&nbsp;
      {closed === false && <Button onClick={() => askPassword('close')}>Đóng bình chọn</Button>}
      {closed === true && <Button onClick={() => askPassword('open')}>Mở bình chọn</Button>}
      {closed === true && <Button onClick={() => askPassword('reset')} variant="secondary" style={{ marginLeft: "5px" }}>RESET</Button>}
    </Card.Body>}
    {!!action && <Card.Body>
      <form onSubmit={submit}>
        Password: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
        &nbsp;&nbsp;&nbsp;
        <Button type="submit">OK</Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" onClick={() => setAction(null)}>Cancel</Button>
      </form>
    </Card.Body>}
  </Card>;
}

function HeaderCard({ closed }) {
  return <Card>
    <Card.Body>
      <Card.Title>
        <b>Kết quả bình chọn: Phần thi tài năng</b>
      </Card.Title>
      <Card.Text>
        <small>Cập nhật sau mỗi 5 giây</small><br/>
        <h5><Badge bg={closed ? 'warning' : 'success'}>
          {closed === false && 'Cổng bình chọn đang mở'}
          {closed === true && 'Cổng bình chọn đã đóng'}
        </Badge></h5>
      </Card.Text>
    </Card.Body>
  </Card>;
}

function Presentation() {
  const {people, closed} = useVoteResult();

  return <>
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <br/><br/>
          <HeaderCard closed={closed} />
          <br/>
        </Col>
        <Col md={6} lg={6}>
          <ResultDisplay people={people} type="King" closed={closed} />
        </Col>
        <Col md={6} lg={6}>
          <ResultDisplay people={people} type="Queen" closed={closed} />
        </Col>
        <Col md={12} lg={12}>
          <br/>
          {closed !== null && <ControlArea closed={closed} />}
        </Col>
      </Row>
    </Container>
  </>;
}

export default Presentation;